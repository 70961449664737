// Importing react modules
import React, { Component } from 'react'

// Importing 3D model viewer module
import ViewerComponent from './components/ViewerComponent'

// Creating parent class component
export class MainComponent extends Component {

  constructor ( props ) {
    super ( props )
    // Reference of the child
    this.childRef = React.createRef()
  }

  // Configuring assets paths
  state = {
    bgImagePath : require( './assets/background.jpg' ), // background image path
    bgLogoPath : require( './assets/backgroundLogoEmpty.png' ), // background logo path
    modelPath : require( './assets/SethBaseball_R1_NoBase.glb' ), // 3D model path
    qrPath : require( './assets/qrCode.png' ), // QR code image path
    scratchPath : require( './assets/scratch.png' ), // Scratch image path
    cubeSidePath : require( './assets/baseSideTexture.jpg' ), // Player name image
    baseTexture: require( './assets/baseTexture.jpg' ), // Base top texture
    modelState : 'Default', // State of the model
    modelRotateSpeed : 0.05, // Rotating speed of the model (higher the value more is the speed)
    modelLoadedInPercentage : 0, // Calculating model loading percentage, can be used for loading screen
    modelOffsetY: -4, // Model up and down parameter (negative value will push the model down and positive value will push the model up)
    baseOffsetY: -3, // Base up and down parameter (negative value will push the base down and positive value will push the base up)
    modelScaleFactor: 500, // Model scale parameter
    baseSizeFactor: 47, // Base size parameter
    status: 0 // Checking the sstatus of the state to render the child component
  }

  componentDidMount = () => {

      // This local JSON is only for demo. It should come from API.
      let modelDetails = {
        "items": [
          { 
            "modelPath": require( './assets/SethBaseball_R1_NoBase.glb' ),
            "modelOffsetY": -4,
            "baseOffsetY": -3,
            "modelScaleFactor": 500,
            "baseSizeFactor": 47,
            "status": 1,
            "isScratch": true
          },
          {
            "modelPath": require( './assets/SethBaseball_R1_NoBase.glb' ),
            "modelOffsetY": -4,
            "baseOffsetY": -3,
            "modelScaleFactor": 500,
            "baseSizeFactor": 47,
            "status": 1,
            "isScratch": false
          }
        ]
      }
      
      // Changing the state dynamically
      this.setState ( modelDetails.items[ Number ( this.props.match.params.id ) - 1 ] )

      // Conditions to handle next Previous
      if(this.props.match.params.id === undefined){

        this.setState ( { status: 1 } )
        this.prevPage = 1
        this.nextPage = 2

      } else {

        if ( Number ( this.props.match.params.id ) < modelDetails.items.length ) {
          this.nextPage = Number ( this.props.match.params.id ) + 1
        } else {
          this.nextPage = Number ( this.props.match.params.id )
        }
    
        if ( Number ( this.props.match.params.id ) !== 1 ) {
          this.prevPage = Number ( this.props.match.params.id ) - 1
        } else {
          this.prevPage = Number( this.props.match.params.id )
        }

      }
      
  }

  // Function to change state of the model
  changeModelState = ( modelState ) => {
    this.setState ( { modelState : modelState } ) 
  }

  // Callback function for background click
  backgroundClick = () => {
    console.log ( 'Background clicked callback function' )
  }

  // Callback function for body click
  bodyClick = () => {
    console.log ( 'Body clicked callback function' )
  }

  // Callback function for base click
  baseClick = () => {
    console.log ( 'Base clicked callback function' )
  }

  // Callback function for head click
  headClick = () => {
    console.log ( 'Head clicked callback function' )
  }

  // Function to change the QR code image
  changeQrCodeTexture = () => {
    // If QR code images are loading from external URLs, then no need to use the require function
    let updatedQrCode = require ( './assets/qrCodeNew.jpg' )
    this.childRef.current.changeQrCodeTexture ( updatedQrCode )

    // (Optional) This is just to ensure that the state always has the updated value of the QR code image
    this.setState ( { qrPath : updatedQrCode } ) 
  }

  // Update the isscratch value once the scratch completed first time
  changeScratchStatus = () => {
    console.log('Scratch value updated');
    
  }

  render() {
    let isDisable
    if (this.state.modelState !== 'Default' ) {
      isDisable = true
    } else {
      isDisable = false
    }

    let { modelState, bgImagePath, bgLogoPath, modelPath, qrPath, scratchPath, cubeSidePath, baseTexture, modelLoadedInPercentage, modelRotateSpeed, modelOffsetY, baseOffsetY, modelScaleFactor, baseSizeFactor, isScratch  } = this.state
    return (
        
        <div className="App">

          {/* Left Arrow */}
          <a href={"/"+this.prevPage} disabled={ isDisable } style = { { display: 'block', zIndex: '9', left: '5px', top: '50%', position: 'absolute',  width: '30px', height: '30px', transform: 'translateY(-50%)'  } } > 
            <img src= { require('./assets/arrowLeft.png') } alt = { "prev" } style = { { width: '30px', height: '30px' } }></img>
          </a>
          
          {/* Right Arrow */}
          <a href={"/"+this.nextPage} disabled={ isDisable } style = { { display: 'block', zIndex: '9', right: '5px', top: '50%', position: 'absolute',  width: '30px', height: '30px', transform: 'translateY(-50%)'  } } > 
            <img src= { require('./assets/arrowRight.png') } alt = { "next" } style = { { width: '30px', height: '30px' } }></img> 
          </a>
          
          {/* Viewer component render */}
          { this.state.status === 1 ? 
            <ViewerComponent ref={this.childRef} modelState = { modelState } bgImagePath = { bgImagePath } bgLogoPath = { bgLogoPath } modelPath = { modelPath } backgroundClick = { this.backgroundClick } bodyClick = { this.bodyClick } baseClick = { this.baseClick }  headClick = { this.headClick } changeModelState = {this.changeModelState} qrPath = {qrPath} scratchPath = {scratchPath} cubeSidePath = {cubeSidePath} baseTexture = { baseTexture } modelLoadedInPercentage = { modelLoadedInPercentage } modelRotateSpeed = { modelRotateSpeed } modelOffsetY = {modelOffsetY} baseOffsetY = {baseOffsetY} modelScaleFactor = { modelScaleFactor } baseSizeFactor = { baseSizeFactor } isScratch={ isScratch } changeScratchStatus = { this.changeScratchStatus } />
          :null
          } 

        </div>
        
    )
  }
}

export default MainComponent
