// Importing react modules
import React, { Component } from 'react'

// Importing required Three.js modules
import { MeshFaceMaterial,  CanvasTexture, TextureLoader, CylinderGeometry, MeshBasicMaterial, Mesh, Raycaster, Vector2, Scene, PerspectiveCamera, WebGLRenderer, AmbientLight, DirectionalLight, HemisphereLight, Clock, AnimationMixer } from 'three'

// Importing GLTF loader to load the 3D model
import GLTFLoader from 'three-gltf-loader'

// Importing three.js camera orbit controls
import OrbitControls from 'three-orbitcontrols'

// Importing tween from tweenmax for camera animations
import TWEEN from 'tween'

// Importing button modules
import BonusButton from "./BonusButton"
import StraightenButton from "./StraightenButton"

// Importing CSS file
import './viewer.css'

// Importing scratch brush
import ScratchBrushPath from "../assets/brush.png"

// Creating the 3D viewer component class
class ViewerComponent extends Component {

  componentDidMount = () => {
    // initializing the variable to check scratch status
    this.isScratch = false;
    // Initializing height and width of the viewer canvas
    this.width = this.mount.clientWidth
    this.height = this.mount.clientHeight

    // Initializing three.js raycast and mouse vector to identify the click events
    this.raycaster = new Raycaster ()
    this.mouse = new Vector2 ()
    this.drawStartPos = new Vector2()
    
    // Initializing three.js objects for 3D model with material, camera, renderer and scene
    this.modelLoader = new GLTFLoader ()
    this.camera = new PerspectiveCamera ( 45, this.width / this.height, 1, 1000 ) // PerspectiveCamera( fov : Number, aspect : Number, near : Number, far : Number )
    this.renderer = new WebGLRenderer ( { antialias: true, alpha: true } )
    this.renderer.domElement.id = 'viewerCanvas'
    this.scene = new Scene ()

    // Setting up initial camera position (camera, x, y, z)
    this.setCameraPosition ( this.camera, 0, 0, -1000 )

    // Calling functions to create and add lights to the scene
    this.setDirectionalLight ( this.scene )
    this.setAmbientLight ( this.scene )
    this.setHemisphereLight ( this.scene )

    // Initializing controls for camera rotation arround the object in all direction and binding change event to track 3D model state (Default, Rotating and Reveal) 
    this.controls = new OrbitControls ( this.camera, this.renderer.domElement )
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.25
    this.controls.rotateSpeed = this.props.modelRotateSpeed
    this.controls.enableZoom = false
    this.controls.enablePan = false
    this.controls.addEventListener ( 'change', this.trackCameraPosition, true )

    // Initializing clock and array for the model head animations
    this.mixers = []
    this.clock = new Clock()

    // Initializing booleans for detecting the mouse movement and events for scratch functionality 
    this.objectState = false
    this.isMouseMoving = false
    this.isMouseClicked = false

    // Defining variable for model path
    this.modelPath = this.props.modelPath
    this.modelOffsetY = this.props.modelOffsetY
    this.baseOffsetY = this.props.baseOffsetY
    this.modelScaleFactor = this.props.modelScaleFactor
    this.baseSizeFactor = this.props.baseSizeFactor
    
    // Calling function to render 3D model to the scene
    this.renderModel ( this.scene )

    // Calling function to set 3D model initial position (rotationAngleX, rotationAngleY, rotationAngleZ, isButtonClicked, zoomFov, isInitializing)
    this.changeCameraPosition ( 0, 0, -400, false, 37, true )
    
    // setting the size of renderer
    this.renderer.setSize ( this.width, this.height )

    // Appending the domElement to canvas
    this.mount.appendChild ( this.renderer.domElement )
    
    // Calling render function 'requestAnimationFrame' of three.js 
    this.start ()

    // Binding window resize and mousedown event
    window.addEventListener ( 'resize', this.handleResize )
    window.addEventListener ( 'mousedown', this.detectMouseClicked, false )
    window.addEventListener ( 'mousemove', this.detectMouseMovement, false )
    window.addEventListener ( 'mouseup', this.removeMouseMovement, false )
    window.addEventListener ( 'mouseleave', this.removeMouseMovement, false )

    // Binding scratch functionality event to touchmove
    window.addEventListener ( 'touchmove', this.onTouchMoveEvent, true )
    window.addEventListener ( 'touchstart', this.detectTouchStart, true )
    window.addEventListener ( 'touchend', this.removeMouseMovement, true )
    
  }
  
  // Initializing and drawing the scratch effect
  draw = ( x, y ) => {
    let brush = new Image()
    brush.src = ScratchBrushPath
    this.scratchCanvasCtx.moveTo ( x, y )
    this.scratchCanvasCtx.globalCompositeOperation = 'destination-out'
    this.scratchCanvasCtx.drawImage ( brush, x, y)

    // Reset drawing start position to current position.
    this.drawStartPos.set( x, y )

    // Need to flag the map as needing updating.
    this.scratchMaterial.map.needsUpdate = true

    // Calling function to get the percentage of scratch using no of pixels filled
    this.handlePercentage( this.getFilledInPixels( 32 ) )
  }

  // Initializing and calculating no of pixels filled
  getFilledInPixels = ( stride ) => {
    if (!stride || stride < 1) { stride = 1 }
    let pixels   = this.scratchCanvasCtx.getImageData( 0, 0, this.canvasWidth, this.canvasHeight ),
        pdata    = pixels.data,
        l        = pdata.length,
        total    = ( l / stride ),
        count    = 0
    // Iterate over all pixels
    for ( let i = count = 0; i < l; i += stride ) {
      if ( parseInt(pdata[i] ) === 0 ) {
        count++
      }
    }
    return Math.round( ( count / total ) * 100 )
  }

  // Initializing and calculating the percentage of scratch
  handlePercentage = ( filledInPixels ) => {
    filledInPixels = filledInPixels || 0
    if ( filledInPixels > 60 ) {
      this.scratchCanvasCtx.clearRect( 0, 0, this.canvasWidth, this.canvasHeight )
      if(!this.isScratch){
        this.isScratch =  true
        this.props.changeScratchStatus()
      }
  
    }
  }
  
  // Setting camera position
  setCameraPosition = ( camera, x, y, z ) => {
    camera.position.x = x
    camera.position.y = y
    camera.position.z = z
  }

  // Initializing and adding directional light to the scene
  setDirectionalLight = ( scene ) => {
    const directionalLight = new DirectionalLight( 0xffffff, 2 ) // DirectionalLight( color : Integer, intensity : Float )
    directionalLight.position.set( 0.5, 0, 0.866 )
    this.camera.add( directionalLight )
    scene.add( this.camera )
  }

  // Initializing and adding ambient light to the scene
  setAmbientLight = ( scene ) => {
    const ambientLight = new AmbientLight( 0xffffff, 1 ) // AmbientLight( color : Integer, intensity : Float )
    scene.add( ambientLight )
  }

  // Initializing and adding hemisphere light to the scene
  setHemisphereLight = ( scene ) => {
    const hemisphereLight = new HemisphereLight( 0xffffff, 0xffffff, 1 ) // HemisphereLight( skyColor : Integer, groundColor : Integer, intensity : Float )
    
    scene.add( hemisphereLight )
  }

  changeModel = (modelDetails) => {

    this.modelPath = modelDetails.modelPath
    this.modelOffsetY = modelDetails.modelOffsetY
    this.baseOffsetY = modelDetails.baseOffsetY
    this.modelScaleFactor = modelDetails.modelScaleFactor
    this.baseSizeFactor = modelDetails.baseSizeFactor
    var chieldElements = this.scene.children.length

    //Removing everything from scene
    while (chieldElements--) {
        if(this.scene.children[chieldElements] instanceof PerspectiveCamera) continue //Leave camera in the scene
        if(this.scene.children[chieldElements] instanceof AmbientLight) continue //leave camera in the scene
        if(this.scene.children[chieldElements] instanceof HemisphereLight) continue //leave camera in the scene
        if(this.scene.children[chieldElements] instanceof DirectionalLight) continue //leave camera in the scene
        this.scene.remove( this.scene.children[ chieldElements ] )
    }
    this.renderModel(this.scene)
  }

  // Loading the 3D model and adding to the scene
  renderModel = ( scene ) => {
    this.modelLoader.load (
      this.modelPath,
      ( gltf ) => {
          const model = gltf.scene
          model.position.z = 3
          model.position.y = -102 + (this.modelOffsetY)
          model.rotation.y = 3.14159
          model.scale.set(this.modelScaleFactor, this.modelScaleFactor, this.modelScaleFactor)

          // Creating base
          this.setBase ( scene )


          // Texture enhance
          model.traverse((object) => {
            const obj = object
            const maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy()
            if ( obj.isMesh === true && obj.material.map !== null ) {
            //if (obj instanceof THREE.Mesh) {
              obj.material.map.anisotropy = maxAnisotropy
              obj.material.roughness  = 1.5

            }
          })

          // Adding model to scene
          scene.add ( model ) 
          
          // Head animation 
          this.mixer = new AnimationMixer ( model ) // AnimationMixer( rootObject : Object3D )
          this.mixers.push ( this.mixer )

          this.animation = gltf.animations[0]
      },
      ( xhr ) => {
          // Updating loading state percentage
          this.setState ({ modelLoadedInPercentage : xhr.loaded / xhr.total * 100 } )
      },
      ( error ) => {
          console.error( 'Some error occured', error )
          // Code for exception handling goes here
      }
    )
  }

  // Setting the base
  setBase = ( baseScene ) => {
    // creating qr code texture
    this.qrCodeTextureLoader = new TextureLoader()

    this.qrCodeTexture = this.qrCodeTextureLoader.load( this.props.qrPath )

    this.qrCodeTexture.crossOrigin = ''
    this.qrCodeTexture.anisotropy = this.renderer.getMaxAnisotropy()
    this.qrCodeTexture.repeat.set( 1.1, 0.96 )
    this.qrCodeTexture.center.set( 0.5, 0.5 )
    this.qrCodeTexture.rotation = 1.56

    // creating base logo side strip
    let baseStripTexture = new TextureLoader().load( this.props.cubeSidePath )
    baseStripTexture.anisotropy = this.renderer.getMaxAnisotropy()
    baseStripTexture.repeat.set( 1.1, 1 )

    // creating base on which model will be placed
    let baseTexture = new TextureLoader().load( this.props.baseTexture )
    baseTexture.anisotropy = this.renderer.getMaxAnisotropy()    
    baseTexture.center.set( 0.5, 0.5 )
    baseTexture.rotation = -0.46
    // Shape of the base CylinderGeometry(radiusTop : Float, radiusBottom : Float, height : Float, radialSegments : Integer, heightSegments : Integer, openEnded : Boolean, thetaStart : Float, thetaLength : Float)
    let baseGeometry = new CylinderGeometry( this.baseSizeFactor, this.baseSizeFactor, 9, 6, 1, false, 0 )
    baseGeometry.center( 0.5, 0.5 )
    baseGeometry.rotateY( -0.02 )

    // clculating the no of sides of complete base and applying textures on all the sides
    let baseGeometryFaces = baseGeometry.faces.length
    
    for ( let i = 0; i < baseGeometryFaces; i++ ) { 
      if ( i < 12 ) {
        baseGeometry.faces[i].materialIndex = 0
      } else if ( i > 11 && i < 18 ) {
        baseGeometry.faces[i].materialIndex = 1
      } else {
        baseGeometry.faces[i].materialIndex = 2
      }
    }

    let baseStripMaterial = new MeshBasicMaterial ( { map: baseStripTexture } )
    let baseMaterial = new MeshBasicMaterial ( { map: baseTexture } )
    this.qrCodeMaterial = new MeshBasicMaterial ()
    this.qrCodeMaterial.map = this.qrCodeTexture
    this.qrCodeMaterial.opacity = 1

    let baseMaterialsArray = []
    baseMaterialsArray.push ( baseStripMaterial ) //materialindex = 0
    baseMaterialsArray.push ( baseMaterial ) // materialindex = 1
    baseMaterialsArray.push ( this.qrCodeMaterial ) // materialindex = 2

    this.baseGeometryMaterial = new MeshFaceMaterial ( baseMaterialsArray )
    this.baseGeometryMaterial.transparent = false
    this.baseGeometryMesh = new Mesh ( baseGeometry, this.baseGeometryMaterial )
    const baseOffsetY = -96.5 + Number(this.baseOffsetY)
    this.baseGeometryMesh.position.set ( 0, baseOffsetY, 0 )
    baseScene.add ( this.baseGeometryMesh )

    // Creating scratchable canvas for scratch effect
    let scratchCanvas = document.getElementById ( 'drawing-canvas' )
    this.drawCanvas = scratchCanvas
    this.scratchCanvasCtx = scratchCanvas.getContext ( '2d' )

    let scratchImage = new Image()
    scratchImage.anisotropy = this.renderer.getMaxAnisotropy()    
    scratchImage.src = this.props.scratchPath

    // Checking if the scratch is already completed by user
    if(!this.props.isScratch){
      // On complete loading of the scratch image adding it to scratchable geometry
      scratchImage.onload = () => {
        // setting the scratchable canvas wdth and height based on the scratchable image
        this.canvasWidth = scratchImage.width
        this.canvasHeight = scratchImage.height
        scratchCanvas.width = this.canvasWidth
        scratchCanvas.height = this.canvasHeight

        // Adding scratchable image to canvas
        this.scratchCanvasCtx.drawImage ( scratchImage, 0, 0 )
            
        // Creating scratchable geomery to append the scratchable canvas
        let scratchTexture = new CanvasTexture ( scratchCanvas )
        scratchTexture.anisotropy = this.renderer.getMaxAnisotropy() 
        scratchTexture.repeat.set ( 1.1, 0.99 )
        scratchTexture.center.set ( 0.5, 0.5 )
        scratchTexture.rotation = 9.95

        // Shape of the base CylinderGeometry(radiusTop : Float, radiusBottom : Float, height : Float, radialSegments : Integer, heightSegments : Integer, openEnded : Boolean, thetaStart : Float, thetaLength : Float)
        let scratchGeometryFactor =  this.baseSizeFactor - 0.5
        let scratchGeometry = new CylinderGeometry ( scratchGeometryFactor, scratchGeometryFactor, 1, 6, 1, false, 0 )
        scratchGeometry.center ( 0.5, 0.5 )
          scratchGeometry.rotateY ( 178 )
          this.scratchMaterial = new MeshBasicMaterial( { map: scratchTexture } )
          this.scratchMaterial.name = "baseGeometry"
          this.scratchGeometryMesh = new Mesh ( scratchGeometry, this.scratchMaterial )
          const scratchOffsetY =  -100.7 + Number(this.baseOffsetY)
          this.scratchGeometryMesh.position.set ( 0, scratchOffsetY, 0 )
          baseScene.add( this.scratchGeometryMesh )
      }
    }
    
  }

  // Function to change the QR code dynamically
  changeQrCodeTexture = ( updatedQrCode ) => {
    this.qrCodeTextureLoader.load ( updatedQrCode, ( texture ) => {
      this.setNewQrCodeTexture ( texture )
    }, false)
  }

  setNewQrCodeTexture = ( texture ) => {
    texture.crossOrigin = ''
    texture.anisotropy = this.renderer.getMaxAnisotropy ()
    texture.repeat.set ( 1.1, 0.96 )
    texture.center.set ( 0.5, 0.5 )
    texture.rotation = 1.56
    this.qrCodeMaterial.map = texture
  }

  // Checking if user is intracting with the model and setting model state to 'Rotating'. As soon as as the camera reaches to the snap threshold the model goes to 'Reveal' position
  trackCameraPosition = () => {
    // Handling button disable property
    this.cameraTracking = true
    this.props.changeModelState ( 'Rotating' )
    let yAxis = this.camera.position.y
    if ( yAxis < 0 ) {
      yAxis = - ( yAxis )
    }
    if ( this.camera.position.x <= 100 && this.camera.position.z <= 100 && yAxis > 350 && this.camera.position.y < 0 ) {
      this.controls.enableRotate = true 
      this.controls.noRotate = true
      this.changeCameraPosition ( 0.2, -490, -11.5, true, 20.5, false )
    } 
  }

  // Changing camera position
  changeCameraPosition = ( rotationAngleX, rotationAngleY, rotationAngleZ, isButtonClicked, zoomFov, isInitializing ) => {
    if ( !isInitializing ) {
      this.props.changeModelState ( 'Rotating' )
    }
    this.rotateCamera ( rotationAngleX, rotationAngleY, rotationAngleZ, isButtonClicked, zoomFov, isInitializing )
  }

  // Rotating camera animation
  rotateCamera = ( rotationAngleX, rotationAngleY, rotationAngleZ, isMovableControl, zoomFov, isInitializing ) => {
      let target = this.camera
      let tween = new TWEEN.Tween ( target.position )
        .to ( {
          x:rotationAngleX,
          y:rotationAngleY,
          z:rotationAngleZ
        }, 1000 ).onUpdate ( function () {
          target.position.x = this.x
          target.position.y = this.y
          target.position.z = this.z
        } )
				this.zoomInOutCamera ( tween, isMovableControl, zoomFov, isInitializing )
  }

  // Zoom-in and zoom-out animation for camera
  zoomInOutCamera = ( tween, isMovableControl, zoomFov, isInitializing ) => {
    let target = this.camera
    
    TWEEN.removeAll()
    let tweenZoom = new TWEEN.Tween( target ).to( { fov: zoomFov }, 500 ).onUpdate( this.updateCameraPosition ).onComplete( () => {  
      this.controls.enableRotate = !isMovableControl 
      this.controls.noRotate = isMovableControl
      if ( isMovableControl ) {
        if(!this.props.isScratch){
          this.scratchMaterial.opacity = 1
          this.scratchMaterial.transparent = true
        }
        
        this.props.changeModelState ( 'Reveal' )
        this.objectState = true
      } else {
        this.objectState = false
        this.props.changeModelState ( 'Default' )
      }

    } )
    tween.chain ( tweenZoom )
    tween.start()
  }

  // Updating camera position
  updateCameraPosition = () => {
      let target = this.camera
      target.updateProjectionMatrix()
  }

  // Identifying the element where click event has been triggered to call parent callback functions
  detectClickElement = ( event ) => {
    
    this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1
    this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1

    this.raycaster.setFromCamera( this.mouse, this.camera )
    let intersects = this.raycaster.intersectObjects( this.scene.children, true )

    if ( intersects.length === 0 ) {
      this.props.backgroundClick()
    } else {
      if ( intersects[0].object.material.name === "headtexture" || intersects[0].object.material.name === "visor" ) {
        this.props.headClick()

        // This is the model animation code which is temporarily disabled as the head/body ratio is not finalized yet
        this.mixer.clipAction ( this.animation ).play()

      } else if ( intersects[0].object.material.name === "bodytexture" ) {
        this.props.bodyClick()

        // This is the model animation code which is temporarily disabled as the head/body ratio is not finalized yet
        this.mixer.clipAction ( this.animation ).play()
        
      } else if ( intersects[0].object.material.name === "baseGeometry" ) {
        this.props.baseClick()
      }
    }
  }

  // Initializing and detecting mouse events
  detectTouchStart = ( e ) => {
    this.isMouseClicked = true
    this.detectClickElement ( e.touches[0] )
    
  }

  // Initializing and detecting mouse events
  detectMouseClicked = ( e ) => {
    this.isMouseClicked = true
    this.detectClickElement ( e )
  }

  // Calculate the offset for scrtch functionality
  trackMouseOffset = (e) => {

    this.xOffset = (this.width / 2) - (this.canvasWidth / 2.5)
    this.yOffset = (this.height / 2) - (this.canvasHeight / 2.5)

    let erasableXOffset = ( e.clientX - this.xOffset )
    let erasableYOffset = ( e.clientY - this.yOffset )
    if(!this.props.isScratch){
      this.draw ( erasableXOffset, erasableYOffset )
    }
  }
  // Initialinizing and adding scratch function on mouse drag
  detectMouseMovement = ( e ) => {
    if ( this.isMouseClicked ) {
      this.isMouseMoving = true
      if ( this.objectState ) {
        this.trackMouseOffset(e) 
      }
    }
  }

   // Scratch functionality on touch move
   onTouchMoveEvent = ( e ) => {
    if ( this.objectState ) {
      let touch = e.touches[0]
      this.xOffset = (this.width / 2) - (this.canvasWidth / 2.5)
      this.yOffset = (this.height / 2) - (this.canvasHeight / 2.5)
  
      let erasableXOffset = ( touch.clientX - this.xOffset )
      let erasableYOffset = ( touch.clientY - this.yOffset )
      if(!this.props.isScratch){
        this.draw( erasableXOffset, erasableYOffset )
      }
    } 
  }

  // Setting the variable to default value for detecting the mouse movement and events for scratch functionality 
  removeMouseMovement = () => {
    this.isMouseMoving = false
    this.isMouseClicked =  false
  }

  // Updating renderer size and camera aspect ratio
  handleResize = () => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
    this.renderer.setSize ( width, height )
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  // Removing all the events bound to window, camera controls and canvas
  componentWillUnmount = () => {
    // Window events
    window.removeEventListener ( 'resize' )
    window.removeEventListener ( 'mousedown' )
    // Three.js/Canvas events
    this.stop()
    this.controls.removeEventListener ( 'change' )
    this.mount.removeChild ( this.renderer.domElement )
  }

  // Calling default start function to render the view
  start = () => {
    if ( !this.frameId ) {
      this.frameId = requestAnimationFrame ( this.animate )
    }
  }

  // Calling default stop function to stop the rendering
  stop = () => {
    cancelAnimationFrame ( this.frameId )
  }

  // Calling default animate function of three.js
  animate = () => {
    TWEEN.update()
    this.frameId = window.requestAnimationFrame ( this.animate )
    this.renderScene()
  }

  // Calling default renderScene function of three.js
  renderScene = () => {

    
    
    this.renderer.autoClear = false
    this.renderer.clear()
    this.renderer.physicallyCorrectLights = true
    this.renderer.gammaInput = true
    this.renderer.gammaOutput = true
    this.renderer.gammaFactor = 2.2
    this.renderer.setPixelRatio( window.devicePixelRatio )
    // Model head animation 
    const delta = this.clock.getDelta()
    for ( let mixer of this.mixers ) {  
      mixer.update( delta )
    }

    this.camera.lookAt( this.scene.position )
    this.renderer.render( this.scene, this.camera )
  }
  
  // Calling default render function of react
  render = () => {
    let button

    if ( this.props.modelState === 'Default' ) {
      button = <BonusButton changeCameraPosition = { this.changeCameraPosition } />
    } else {
      button = <StraightenButton changeCameraPosition = { this.changeCameraPosition } />
    }

    return (
      <div>
        <canvas id="drawing-canvas"></canvas>
      
        <div
          onTouchMove={this._onTouchMove}
          className="viewer-container"
          style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', backgroundImage: 'url(' + this.props.bgLogoPath + ') , url(' + this.props.bgImagePath +  ')'}}
          ref={mount => {
            this.mount = mount
          }}
        />
        {button}
      </div>
    )
  }
}

export default ViewerComponent